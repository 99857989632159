<template>
  <div>
    <p class="has-text-weight-bold is-size-4 mb-1 review-title">
      {{
        isMortgage
          ? $t("af:mortgage_application.offline_under_review.title")
          : $t("af:application.offline_under_review.title")
      }}
    </p>
    <p class="mb-5 review-subtitle">
      {{
        isMortgage
          ? $t("af:mortgage_application.offline_under_review.subtitle")
          : $t("af:application.offline_under_review.subtitle")
      }}
    </p>
    <div class="review-card">
      <application-template
        v-if="isMortgage"
        template="MORTGAGE_OFFLINE_IN_REVIEW"
      ></application-template>
      <application-template
        v-else
        template="OFFLINE_IN_REVIEW"
      ></application-template>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationFlowOfflineUnderReview",
  components: {
    ApplicationTemplate: () => import("@/components/ApplicationTemplate.vue"),
  },
  props: {},
  computed: {
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.mbh {
  .review-title {
    font-size: 48px !important;
    font-weight: 800;
    line-height: 56px;
    color: #212121;
    margin-bottom: 32px !important;
  }

  .review-description {
    color: #051c3a;
    font-size: 16px;
  }

  .review-card {
    padding: 32px;
    border-radius: 24px;
    background: white;
  }
}
</style>
